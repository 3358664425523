// @flow
import { filter } from "lodash-es";
import type { AppQueryResponse } from "../__generated__/AppQuery.graphql";
type Projects = $PropertyType<AppQueryResponse, "projects">;
type Project = $ElementType<Projects, 0>;

// convert to a normalised search query
const parseSearchInput = (input: string): Array<string> => input.split(' OR ').map(i => i.toLowerCase());

export const filterProjects = (
  queryString: ?string,
  projects: Projects
): Projects => {
  if (!queryString) return projects;
  const queryArray = parseSearchInput(queryString);
  const filteredIds = new Set();
  // at the moment this only does an OR
  queryArray.forEach(queryString =>
    projects.forEach(
      project =>
        project &&
        deepContains(project, queryString).length &&
        filteredIds.add(project.id) // add to the set of ids
    )
  );
  return projects.filter(project => project && filteredIds.has(project.id));
};

// Recursively check object/array values for equality
function deepContains(input: Object | Array<any>, str: string): Array<any> {
  return filter(input, val => {
    if (typeof val === "object") {
      if (deepContains(val, str).length > 0) {
        return true;
      }
    }
    if (typeof val === 'number') return String(val).search(str) !== -1;
    if (typeof val === "string") return val.toLowerCase().search(str) !== -1;
  });
}

export type SortableFieldstype =
  | "COMPANY"
  | "SECTOR"
  | "SIZE"
  | "PROGRAMME"
  | "VALUE";

// we use strings to sort because it is more flexible
const sortProjectsFieldMap = (
  project: Project,
  field: SortableFieldstype
): string => {
  switch (field) {
    case "COMPANY":
      return project.organisation.name;
    case "SECTOR":
      return project.organisation.sectors.join(", "); // TODO: sort on sectors a bit better
    case "SIZE":
      switch (project.organisation.size) {
        // convert to numbers that can be compared in sequence
        case "MICRO":
          return "0";
        case "SMALL":
          return "1";
        case "MEDIUM":
          return "2";
        case "LARGE":
          return "3";
        default:
          return "";
      }
    case "PROGRAMME":
      if (project.programme) {
        return `${project.programme.year}-${project.programme.quarter}`;
      }
      return "";
    case "VALUE":
      if (!project.value) return "";
      return String(Math.round(project.value));
    default:
      return "";
  }
};
export function sortProjects(
  sortBy: ?SortableFieldstype,
  sortDirection: "ASC" | "DESC",
  projects: *
): * {
  const sortableArray = Array.from(projects); // the input array is readonly
  if (typeof sortBy !== "string") return projects;
  return sortableArray.sort((projectA, projectB) => {
    const a = sortProjectsFieldMap(projectA, sortBy);
    const b = sortProjectsFieldMap(projectB, sortBy);
    if (sortDirection === "ASC") {
      if (a > b) {
        return -1;
      }
      return 1;
    }
    if (sortDirection === "DESC") {
      if (a > b) {
        return 1;
      }
      return -1;
    }
    return 0;
  });
}
