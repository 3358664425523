/* eslint-disable import/first */
// add Sentry
import * as Sentry from '@sentry/browser';
Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

// add Mixpanel
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import cookie from 'cookie';
import registerServiceWorker from './registerServiceWorker';

// set up dev cookie

async function main() {
  // set development cookie
  if (process.env.NODE_ENV === 'development') {
    // get user info using async import
    const devUser = await import('./devUser.json');
    document.cookie = cookie.serialize('pivigo_auth', devUser.default);
  }
  ReactDOM.render(<App />, document.getElementById('root'));
  registerServiceWorker();
}

main();
