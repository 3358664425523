/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type projectRowContainer_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type tableContainer_projects$ref: FragmentReference;
declare export opaque type tableContainer_projects$fragmentType: tableContainer_projects$ref;
export type tableContainer_projects = $ReadOnlyArray<{|
  +value: ?number,
  +$fragmentRefs: projectRowContainer_project$ref,
  +$refType: tableContainer_projects$ref,
|}>;
export type tableContainer_projects$data = tableContainer_projects;
export type tableContainer_projects$key = {
  +$data?: tableContainer_projects$data,
  +$fragmentRefs: tableContainer_projects$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "tableContainer_projects",
  "type": "Project",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "value",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "projectRowContainer_project",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '675839e402e517ae8bf8ff812685ce49';
module.exports = node;
