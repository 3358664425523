// @flow
import React from "react";
import mixpanel from 'mixpanel-browser';
import ProjectRowContainer from "../container/project-row-container";
import type { tableContainer_projects } from "../container/__generated__/tableContainer_projects.graphql";
import type { tableContainer_enumValues } from "../container/__generated__/tableContainer_enumValues.graphql";
import type { SortableFieldstype } from "../../lib/search";
type Props = {
  costSum: number,
  projects: tableContainer_projects,
  enumValues: tableContainer_enumValues,
  onToggleSortDirection: () => void,
  onChangeSortBy: SortableFieldstype => void,
  onClickSearchableField: string => void,
};

const Table = ({
  costSum,
  projects,
  enumValues,
  onToggleSortDirection,
  onChangeSortBy,
  onClickSearchableField,
}: Props) => (
  <table id="results-table" className="table tablesorter">
    <thead>
      <tr>
        <th
          className="header"
          onClick={() => {
            mixpanel.track('Sort Header Clicked', { sortBy: 'Company' });
            onToggleSortDirection();
            onChangeSortBy("COMPANY");
          }}
        >
          Company
          <span
            className="glyphicon glyphicon-sort-by-attributes"
            aria-hidden="true"
          />
        </th>
        <th
          className="header"
          onClick={() => {
            mixpanel.track('Sort Header Clicked', { sortBy: 'Sector' });
            onToggleSortDirection();
            onChangeSortBy("SECTOR");
          }}
        >
          Sector
          <span
            className="glyphicon glyphicon-sort-by-attributes"
            aria-hidden="true"
          />
        </th>
        <th
          className="header"
          onClick={() => {
            mixpanel.track('Sort Header Clicked', { sortBy: 'Size' });
            onToggleSortDirection();
            onChangeSortBy("SIZE");
          }}
        >
          Size
          <span
            className="glyphicon glyphicon-sort-by-attributes"
            aria-hidden="true"
          />
        </th>
        <th
          className="header"
          onClick={() => {
            mixpanel.track('Sort Header Clicked', { sortBy: 'Programme' });
            onToggleSortDirection();
            onChangeSortBy("PROGRAMME");
          }}
        >
          Programme
          <span
            className="glyphicon glyphicon-sort-by-attributes"
            aria-hidden="true"
          />
        </th>
        <th
          className="header"
          onClick={() => {
            mixpanel.track('Sort Header Clicked', { sortBy: 'Value' });
            onToggleSortDirection();
            onChangeSortBy("VALUE");
          }}
        >
          £ (K)
          <span className="badge badge-pill badge-primary">
            Sum:
            <span id="cost-sum"> {costSum}</span>
          </span>
          <span
            className="glyphicon glyphicon-sort-by-attributes"
            aria-hidden="true"
          />
        </th>
        <th>Techniques Used</th>
        <th>Project Summary</th>
      </tr>
    </thead>
    <tbody>
      {projects.map((project, i) => (
        <ProjectRowContainer
          onClickSearchableField={onClickSearchableField}
          enumValues={enumValues}
          key={i}
          project={project}
        />
      ))}
    </tbody>
  </table>
);

export default Table;
