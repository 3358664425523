import {
  Environment,
  Network,
  RecordSource,
  Store,
} from 'relay-runtime';
import cookie from 'cookie';
import mixpanel from 'mixpanel-browser';
import jwtDecode from 'jwt-decode';
import { captureException } from '@sentry/browser';

const cookies = cookie.parse(document.cookie);
const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
const gridDomain = process.env.REACT_APP_DATA_URL;

function redirectToAuthPage() {
  window.location.href = `${authDomain}/login`;
}

async function fetchQuery(
  operation,
  variables,
) {
  if (!cookies.pivigo_auth) return redirectToAuthPage();

  const response = await fetch(`${gridDomain}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${cookies.pivigo_auth}`
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  });

  if (!response.ok) {
    // if not authorised then redirect to login page
    if (response.status === 401) {
      return redirectToAuthPage();
    }
    // get body and send error
    const body = await response.text();
    const e = new Error(`Error processing network request: ${body}`);
    captureException(e);
    throw e;
  }

  // from this point on the JWT has been verified
  const claim = jwtDecode(cookies.pivigo_auth);

  // Change Mixpanel identification TODO: this should only be done on initial fetch
  if (mixpanel.get_distinct_id() !== claim.user_id) {
    mixpanel.identify(claim.user_id);
  }

  return response.json();
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});

export default environment;
