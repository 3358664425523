// @flow
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import React, { Component } from "react";
import mixpanel from "mixpanel-browser";
import { QueryRenderer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { debounce } from "lodash-es";
import environment from "./relay";
import TableContainer from "./components/container/table-container";
import SearchContainer from "./components/container/search-container";
import { filterProjects, sortProjects } from "./lib/search";
import type { AppQueryResponse } from "./__generated__/AppQuery.graphql";
import type { SortableFieldstype } from "./lib/search";
type State = {
  searchQuery: string,
  projects: ?$PropertyType<AppQueryResponse, "projects">,
  sortBy: ?SortableFieldstype,
  sortDirection: "ASC" | "DESC"
};

class App extends Component<{}, State> {
  state: State = {
    searchQuery: "",
    sortBy: null,
    sortDirection: "ASC",
    projects: null
  };

  handleClickSearchableField = (text: string) =>
    this.setState({ searchQuery: text });

  handleToggleSortDirection = () => {
    let newState;
    this.state.sortDirection === "DESC"
      ? (newState = "ASC")
      : (newState = "DESC");
    this.setState({
      sortDirection: newState
    });
  };

  handleChangeSortBy = (newBy: SortableFieldstype) => {
    this.setState({ sortBy: newBy });
  };

  // Consider refactoring this to make it more declarative - the search term change should effect the debouncing
  handleSetNewSearchQuery = (
    text: string,
    projects: $PropertyType<AppQueryResponse, "projects">
  ) => {
    // reset if a search field was entered again (useful for toggling search fields)
    const searchQuery = text === this.state.searchQuery ? "" : text;
    this.setState(
      {
        searchQuery
      },
      () => {
        this.updateFilteredProjects(searchQuery, projects);
      }
    );
  };

  updateFilteredProjects = debounce((searchQuery, projects) => {
    mixpanel.track("Search", { query: searchQuery });
    this.setState({
      projects: filterProjects(searchQuery, projects)
    });
  }, 500);

  render() {
    return (
      <QueryRenderer
        environment={environment}
        // root query
        query={graphql`
          query AppQuery {
            projects {
              id # used to return unique results from search
              ...projectRowContainer_project @relay(mask: false) # we add this here so we can make it available for searching
              ...tableContainer_projects
            }
            __schema {
              ...tableContainer_enumValues
            }
          }
        `}
        variables={{}}
        render={({ error, props }) => {
          let appView;
          if (error) {
            appView = (
              <div>
                {error.message} {console.error(error)}
              </div>
            );
          }
          if (!appView && (!props || !props.projects)) {
            appView = <div>Loading...</div>;
          }
          // if we are in an acceptable state then load the app proper app contents
          if (!appView && props) {
            appView = (
              <React.Fragment>
                <div id="title">
                  <img
                    alt="Pivigo logo"
                    src="https://www.pivigo.com/images/logo-sticky.png"
                  />
                  <span>| GRID</span>
                </div>
                <SearchContainer
                  searchQuery={this.state.searchQuery}
                  onSearch={text =>
                    this.handleSetNewSearchQuery(text, props.projects)
                  }
                />

                <div id="results">
                  <p>
                    Results:
                    <span id="result-count">
                      {" "}
                      {this.state.projects
                        ? this.state.projects.length
                        : props.projects.length}
                    </span>
                  </p>
                </div>
                <TableContainer
                  onClickSearchableField={text =>
                    this.handleSetNewSearchQuery(text, props.projects)
                  }
                  enumValues={props.__schema}
                  projects={sortProjects(
                    this.state.sortBy,
                    this.state.sortDirection,
                    this.state.projects || props.projects
                  )}
                  onToggleSortDirection={this.handleToggleSortDirection}
                  onChangeSortBy={this.handleChangeSortBy}
                />
              </React.Fragment>
            );
          }
          return (
            <div id="app" className="container">
              {appView}
            </div>
          );
        }}
      />
    );
  }
}

export default App;
