/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type projectRowContainer_enumValues$ref: FragmentReference;
declare export opaque type projectRowContainer_enumValues$fragmentType: projectRowContainer_enumValues$ref;
export type projectRowContainer_enumValues = {|
  +types: $ReadOnlyArray<{|
    +name: ?string,
    +enumValues: ?$ReadOnlyArray<{|
      +name: string
    |}>,
  |}>,
  +$refType: projectRowContainer_enumValues$ref,
|};
export type projectRowContainer_enumValues$data = projectRowContainer_enumValues;
export type projectRowContainer_enumValues$key = {
  +$data?: projectRowContainer_enumValues$data,
  +$fragmentRefs: projectRowContainer_enumValues$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "projectRowContainer_enumValues",
  "type": "__Schema",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "types",
      "storageKey": null,
      "args": null,
      "concreteType": "__Type",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "enumValues",
          "storageKey": null,
          "args": null,
          "concreteType": "__EnumValue",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cb1e5124164b3c5927c2071f36c7d944';
module.exports = node;
