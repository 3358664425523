// @flow
import React from "react";
import type { projectRowContainer_project } from "../container/__generated__/projectRowContainer_project.graphql";

// project technique to colour mapping
const pillTypes = ["primary", "success", "danger", "warning", "info"];
const getProjectTechniqueClass = (techniques, technique): string =>
  pillTypes[techniques.indexOf(technique) % pillTypes.length];

type Props = {
  technique: string,
  techniquesEnumValues: $PropertyType<projectRowContainer_project, 'techniques'>,
};

const ClassificationPill = ({ technique, techniquesEnumValues }: Props) => (
  <span
    className={`searchable badge badge-${getProjectTechniqueClass(
      techniquesEnumValues,
      technique
    )}`}
  >
    {technique.replace('_', ' ')}
  </span>
);

export default ClassificationPill;
