import React from "react";

const Search = ({ onSearch, searchQuery }) => (
  <div id="search">
    <div className="input-group">
      <input
        id="search-text-entry"
        type="text"
        value={searchQuery}
        onChange={e => onSearch(e.target.value)}
        className="form-control"
        placeholder="Search for..."
      />
    </div>
  </div>
);

export default Search;
