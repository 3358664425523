import React from "react";
import mixpanel from "mixpanel-browser";
import CompanySize from "./company-size";
import ClassificationPill from "./classification-pill";

import type { projectRowContainer_project } from "../container/__generated__/projectRowContainer_project.graphql";
import type { projectRowContainer_enumValues } from "../container/__generated__/projectRowContainer_enumValues.graphql";

type Props = {
  project: projectRowContainer_project,
  enumValues: projectRowContainer_enumValues,
  onClickSearchableField: string => void
};

// get the enums from the schema
const getTechniqueEnums = techniqueEnumValuesToList => {
  const dsTechniques = techniqueEnumValuesToList.types.find(
    type => type.name === "DSTechniques"
  );
  if (dsTechniques && dsTechniques.enumValues) {
    return dsTechniques.enumValues.map(val => val.name);
  }
  return [];
};

const ProjectRow = ({
  project: {
    salesSummary,
    summary,
    value,
    techniques,
    organisation: { id, name, sectors, size },
    programme
  },
  enumValues,
  onClickSearchableField
}: Props) => (
  <tr>
    <td
      className="searchable"
      onClick={() => {
        mixpanel.track("Organisation Cell Clicked", {
          organisation_id: id,
          organisation_name: name
        });
        onClickSearchableField(name);
      }}
    >
      {name}
    </td>
    <td
      className="searchable"
      onClick={() => {
        mixpanel.track("Sector Cell Clicked", { sectors }); // Send as list
        onClickSearchableField(sectors.join(" OR "));
      }}
    >
      {sectors.map((sector, i) => (
        <span key={i}>
          {sector.replace("_", " ")}
          <br />
          <br />
        </span>
      ))}
    </td>
    <td
      className="searchable"
      onClick={() => {
        mixpanel.track("Size Cell Clicked", {
          organisation_name: name,
          organisation_id: id
        });
        onClickSearchableField(size);
      }}
    >
      <CompanySize size={size} />
    </td>
    <td
      className="searchable"
      onClick={() => {
        mixpanel.track("Programme Cell clicked", { programme });
        onClickSearchableField(String(programme.year));
      }}
    >
      {programme && `Q${programme.quarter} ${programme.year}`}
    </td>
    <td className="cost">{value}</td>
    <td>
      {techniques &&
        techniques.map(
          (technique, i) =>
            technique && (
              <ClassificationPill
                key={i}
                technique={technique}
                techniquesEnumValues={getTechniqueEnums(enumValues)}
              />
            )
        )}
    </td>
    <td className="summary">{summary}</td>
  </tr>
);

export default ProjectRow;
