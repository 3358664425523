// @flow
import React from "react";
import type { Size } from '../container/__generated__/projectRowContainer_project.graphql';

type Props = {
  size: ?Size;
}

const CompanySize = ({ size }: Props) => {
  if (!size) return null;
  let sizeNum;
  let sizeText;
  let sizeClass;
  switch (size) {
    case 'LARGE':
      sizeNum = 3
      sizeText = 'Large (250+)'
      sizeClass = "size-icon-large"
      break;
    case 'MEDIUM':
      sizeNum = 2
      sizeText = 'Medium (50-249)';
      sizeClass = 'size-icon-medium';
      break;
    case 'SMALL':
      sizeNum = 1
      sizeText = 'Small (10-49)'
      sizeClass = 'size-icon-small';
      break;
    case 'MICRO':
      sizeNum = 0
      sizeText = 'Micro (1-9)'
      sizeClass = 'size-icon-micro';
      break;
    default:
      throw new Error('Unrecognised size');
  }
  return (
    <React.Fragment>
      <span className="invisible">{sizeNum}</span>
      <div className={sizeClass} />
      <span className="search-text">{sizeText}</span>
    </React.Fragment>
  );
}

export default CompanySize;
