// @flow
import React from "react";
import { createFragmentContainer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import Table from "../presentational/table";
import type { tableContainer_projects } from "./__generated__/tableContainer_projects.graphql";
import type { tableContainer_enumValues } from "./__generated__/tableContainer_enumValues.graphql";
import type { SortableFieldstype } from "../../lib/search";
type Props = {
  projects: tableContainer_projects,
  enumValues: tableContainer_enumValues,
  onChangeSortBy: SortableFieldstype => void,
  onToggleSortDirection: () => void,
  onClickSearchableField: string => void,
};

class TableContainer extends React.Component<Props> {
  sumCost = () =>
    this.props.projects.reduce((acc, item) => acc + Number(item.value), 0).toPrecision(6);

  render() {
    return <Table costSum={this.sumCost()} {...this.props} />;
  }
}

export default createFragmentContainer(TableContainer, {
  projects: graphql`
    fragment tableContainer_projects on Project @relay(plural: true) {
      value
      ...projectRowContainer_project
    }
  `,
  enumValues: graphql`
    fragment tableContainer_enumValues on __Schema {
      ...projectRowContainer_enumValues
    }
  `
});
