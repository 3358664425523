/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type projectRowContainer_enumValues$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type tableContainer_enumValues$ref: FragmentReference;
declare export opaque type tableContainer_enumValues$fragmentType: tableContainer_enumValues$ref;
export type tableContainer_enumValues = {|
  +$fragmentRefs: projectRowContainer_enumValues$ref,
  +$refType: tableContainer_enumValues$ref,
|};
export type tableContainer_enumValues$data = tableContainer_enumValues;
export type tableContainer_enumValues$key = {
  +$data?: tableContainer_enumValues$data,
  +$fragmentRefs: tableContainer_enumValues$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "tableContainer_enumValues",
  "type": "__Schema",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "projectRowContainer_enumValues",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3fdff27e5e10d58dbd5536c0b6b4b1e0';
module.exports = node;
