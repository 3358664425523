/**
 * @flow
 * @relayHash b8a32062c992bda76eb3a16b0f768255
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type tableContainer_enumValues$ref = any;
type tableContainer_projects$ref = any;
export type DSTechniques = "PLACEHOLDER" | "%future added value";
export type Sector = "PLACEHOLDER" | "%future added value";
export type Size = "LARGE" | "MEDIUM" | "MICRO" | "SMALL" | "%future added value";
export type AppQueryVariables = {||};
export type AppQueryResponse = {|
  +projects: $ReadOnlyArray<{|
    +id: string,
    +summary: ?string,
    +value: ?number,
    +techniques: $ReadOnlyArray<?DSTechniques>,
    +organisation: {|
      +id: string,
      +name: string,
      +size: ?Size,
      +sectors: $ReadOnlyArray<Sector>,
    |},
    +programme: ?{|
      +id: string,
      +year: number,
      +quarter: number,
    |},
    +$fragmentRefs: tableContainer_projects$ref,
  |}>,
  +__schema: {|
    +$fragmentRefs: tableContainer_enumValues$ref
  |},
|};
export type AppQuery = {|
  variables: AppQueryVariables,
  response: AppQueryResponse,
|};
*/


/*
query AppQuery {
  projects {
    id
    summary
    value
    techniques
    organisation {
      id
      name
      size
      sectors
    }
    programme {
      id
      year
      quarter
    }
    ...tableContainer_projects
  }
  __schema {
    ...tableContainer_enumValues
  }
}

fragment tableContainer_projects on Project {
  value
  ...projectRowContainer_project
}

fragment tableContainer_enumValues on __Schema {
  ...projectRowContainer_enumValues
}

fragment projectRowContainer_enumValues on __Schema {
  types {
    name
    enumValues {
      name
    }
  }
}

fragment projectRowContainer_project on Project {
  summary
  value
  techniques
  organisation {
    id
    name
    size
    sectors
  }
  programme {
    id
    year
    quarter
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "summary",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "techniques",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "organisation",
  "storageKey": null,
  "args": null,
  "concreteType": "Organisation",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "size",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sectors",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "programme",
  "storageKey": null,
  "args": null,
  "concreteType": "S2DSProgramme",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "year",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "quarter",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AppQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "projects",
        "storageKey": null,
        "args": null,
        "concreteType": "Project",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "tableContainer_projects",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__schema",
        "storageKey": null,
        "args": null,
        "concreteType": "__Schema",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "tableContainer_enumValues",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AppQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "projects",
        "storageKey": null,
        "args": null,
        "concreteType": "Project",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__schema",
        "storageKey": null,
        "args": null,
        "concreteType": "__Schema",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "types",
            "storageKey": null,
            "args": null,
            "concreteType": "__Type",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "enumValues",
                "storageKey": null,
                "args": null,
                "concreteType": "__EnumValue",
                "plural": true,
                "selections": [
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AppQuery",
    "id": null,
    "text": "query AppQuery {\n  projects {\n    id\n    summary\n    value\n    techniques\n    organisation {\n      id\n      name\n      size\n      sectors\n    }\n    programme {\n      id\n      year\n      quarter\n    }\n    ...tableContainer_projects\n  }\n  __schema {\n    ...tableContainer_enumValues\n  }\n}\n\nfragment tableContainer_projects on Project {\n  value\n  ...projectRowContainer_project\n}\n\nfragment tableContainer_enumValues on __Schema {\n  ...projectRowContainer_enumValues\n}\n\nfragment projectRowContainer_enumValues on __Schema {\n  types {\n    name\n    enumValues {\n      name\n    }\n  }\n}\n\nfragment projectRowContainer_project on Project {\n  summary\n  value\n  techniques\n  organisation {\n    id\n    name\n    size\n    sectors\n  }\n  programme {\n    id\n    year\n    quarter\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0f1b793dbe5f89ad281a87c725cc01bf';
module.exports = node;
