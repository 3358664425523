import { createFragmentContainer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import ProjectRow from "../presentational/project-row";

export default createFragmentContainer(ProjectRow, {
  project: graphql`
    fragment projectRowContainer_project on Project {
      summary
      value
      techniques
      organisation {
        id
        name
        size
        sectors
      }
      programme {
        id
        year
        quarter
      }
    }
  `,
  enumValues: graphql`
    fragment projectRowContainer_enumValues on __Schema {
      types {
        name
        enumValues {
          name
        }
      }
    }
  `
});
