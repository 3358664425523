/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type DSTechniques = "PLACEHOLDER" | "%future added value";
export type Sector = "PLACEHOLDER" | "%future added value";
export type Size = "LARGE" | "MEDIUM" | "MICRO" | "SMALL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type projectRowContainer_project$ref: FragmentReference;
declare export opaque type projectRowContainer_project$fragmentType: projectRowContainer_project$ref;
export type projectRowContainer_project = {|
  +summary: ?string,
  +value: ?number,
  +techniques: $ReadOnlyArray<?DSTechniques>,
  +organisation: {|
    +id: string,
    +name: string,
    +size: ?Size,
    +sectors: $ReadOnlyArray<Sector>,
  |},
  +programme: ?{|
    +id: string,
    +year: number,
    +quarter: number,
  |},
  +$refType: projectRowContainer_project$ref,
|};
export type projectRowContainer_project$data = projectRowContainer_project;
export type projectRowContainer_project$key = {
  +$data?: projectRowContainer_project$data,
  +$fragmentRefs: projectRowContainer_project$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "projectRowContainer_project",
  "type": "Project",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "summary",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "value",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "techniques",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "organisation",
      "storageKey": null,
      "args": null,
      "concreteType": "Organisation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "size",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sectors",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "programme",
      "storageKey": null,
      "args": null,
      "concreteType": "S2DSProgramme",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "year",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quarter",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '2ec22b7c2d7aa9cbbc8df76780fa2b39';
module.exports = node;
